import axios from 'axios'

let tolinkUrl = ''
let tolinkToken = ''
let businessUsername = ''
let businessToken = ''

export function tolink_SetToken(token) {
  tolinkToken = token
}

export function tolink_SetUrl(url) {
  tolinkUrl = url
}

export function setBusinessMsg(parmas) { // 设置业务云信息
  const { userName, token } = parmas
  businessUsername = userName
  businessToken = token
}

export function login(params) { // 登录
  let data = {}
  if (params.username) {
    data = {
      name: params.username,
      password: params.password
    }
  } else {
    data = {
      telephone: params.telephone,
      password: params.password
    }
  }
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      baseURL: tolinkUrl,
      url: "/go/auth/login",
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data
    }).then(res => {
      resolve(res)
    })
  })
}

export function tolink_Stream() { // 获取数据流列表
  return axios({
    method: "post",
    baseURL: tolinkUrl,
    url: "/go/stream/findpage",
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': tolinkToken,
    },
    params: { Pagenum: 1, Pagesize: 10000, Orderobject: "name", Ordervalue: 1, "Function": "intact" },
  })
}

export function tolink_Label(apiKey) { // 获取数据流中的标签列表
  return axios({
    method: "post",
    baseURL: tolinkUrl,
    url: "/go/api/getlabel",
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    params: { Apikey: apiKey },
  })
}

export function tolink_GetLine(now, timelength, pointlength, line_points) { // 获取数据流中的标签列表
  return axios({
    method: "post",
    baseURL: tolinkUrl,
    url: "/go/api/getline",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
    params: {
      Datestart: now - timelength * 60,
      Dateend: now,
      Pagenum: 1,
      Pagesize: pointlength,
    },
    data: line_points,
  })
}

export function tolink_Realtime(apiKey, list) { // 获取节点下多个数据点的即时数据
  return axios({
    method: "post",
    baseURL: tolinkUrl,
    url: "/go/api/getdata",
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    params: { Apikey: apiKey, "Function": "realtime" },
    data: JSON.stringify(list),
  })
}

export function tolink_Pagetimedata(apiKey, params) { // 获取单个数据点历史数据
  const Pagesize = params.Pagesize // 分页大小
  const Pagenum = params.Pagenum // 分页页数
  const Datestart = params.Datestart // 开始时间（时间戳，秒）
  const Dateend = params.Dateend // 结束时间（时间戳，秒）
  return axios({
    method: "post",
    baseURL: tolinkUrl,
    url: "/go/api/getdata",
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    params: { Apikey: apiKey, Function: 'pagetime', Pagesize, Pagenum, Datestart, Dateend },
    data: {
      labelID: params.labelID
    }
  })
}

export function tolink_Mqttwrite(apiKey, labelID, value) {
  return axios({
    method: "post",
    baseURL: tolinkUrl,
    url: "/go/api/command",
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    params: { Apikey: apiKey, "Function": "mqtt_write" },
    data: JSON.stringify({ data: labelID, value }),
  })
}

export function saveOperateRecord(record) { // 保存操作记录
  console.log(businessUsername)
  return axios({
    method: 'post',
    baseURL: tolinkUrl,
    url: '/business/collect/operatorRecord',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    params: {
      username: businessUsername,
      token: businessToken
    },
    data: record
  })
}
