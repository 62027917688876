import axios from 'axios';

var dynaltUrl = 'https://dynalt.ditoo.com.cn' // 采集云是域名时使用
var dynaltToken = '';

export function dynalt_SetToken(token) {
  dynaltToken = token;
}

export function dynalt_SetUrl(url) {
  dynaltUrl = url;
}

export function dynalt_Auth(token, userid) {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/auth',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': token,
    },
    params: { userid }
  })
}

export function dynalt_Create(data) {
  return axios({
    method: 'post',
    baseURL: dynaltUrl,
    url: '/go/create',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': dynaltToken,
    },
    data: data,
    params: {},
  })
}

export function dynalt_List() { // 获取组态图全部详细数据列表
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/list',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': dynaltToken,
    },
    params: {}
  })
}

export function dynalt_List2() { // 获取组态图部分数据列表
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/list2',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': dynaltToken,
    },
    params: {}
  })
}

export function dynalt_Update(data) {
  return axios({
    method: 'post',
    baseURL: dynaltUrl,
    url: '/go/update',
    data: data,
    params: {}
  })
}

export function dynalt_Delete(id) {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/delete',
    params: { id: id }
  })
}

export function dynalt_Upload(param) {
  return axios.post(
    dynaltUrl + '/go/upload',
    param, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function dynalt_Retrieve(id) { // 获取组态图信息
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/retrieve',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': dynaltToken,
    },
    params: { id }
  })
}

export function dynalt_Retrieve2(id, pwd, url) {
  if (url) { // 重置url
    dynalt_SetUrl(url)
  }
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/retrieve2',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': dynaltToken,
    },
    params: { pwd, id }
  })
}

export function dynalt_Config(data) { // 保存组态图配置信息
  return axios({
    method: 'post',
    baseURL: dynaltUrl,
    url: '/go/config',
    data: data,
    params: {}
  })
}


export function dynalt_Material_Label() {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/material/label',
    params: {}
  })
}

export function dynalt_Material_List(label) {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/material/list',
    params: { label: label }
  })
}

export function dynalt_Material_Save(url) {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/material/save',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': dynaltToken,
    },
    params: { url }
  })
}

export function dynalt_Material_Private() {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/material/private',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': dynaltToken,
    },
    params: {}
  })
}

export function dynalt_Material_Delete(url) {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/material/delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': dynaltToken,
    },
    params: { url }
  })
}

export function dynalt_Material_Upload(param) {
  return axios.post(
    dynaltUrl + '/go/material/upload',
    param, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': dynaltToken,
    },
  })
}

export function dynalt_Material_Cover(param) {
  return axios.post(
    dynaltUrl + '/go/material/cover',
    param, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function dynalt_Image_Upload(data) {
  return axios({
    method: 'post',
    baseURL: dynaltUrl,
    url: '/go/image/upload',
    data: data,
    params: {}
  })
}

export function dynalt_Image_List(id) {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/image/list',
    params: { id }
  })
}

export function dynalt_Image_Delete(id, url) {
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/image/delete',
    params: { id, url }
  })
}


export function dynalt_Audio_Upload(data) { // 视频素材上传
  return axios({
    method: 'post',
    baseURL: dynaltUrl,
    url: '/go/other_files/upload',
    data: data,
    params: {}
  })
}

export function dynalt_Audio_List(id) { // 视频素材列表
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/other_files/list',
    params: { id }
  })
}

export function dynalt_Audio_Delete(url) { // 删除视频素材
  return axios({
    method: 'get',
    baseURL: dynaltUrl,
    url: '/go/other_files/delete',
    params: { url }
  })
}
