import { removeIpAndPort, strSetArr } from './utils.js'

export function setPictureList(This, list) { // 设置图片列表
  if (This.userid === `111.9.53.181:8000` || This.userid === '111.9.53.181:8001') { // 龙头石
    for(let i = 0; i < list.length; i++) {
      list[i] = `http://111.9.53.181:8002${list[i]}`
    }
  }
  if (This.userid === '192.168.0.68:8001') { // 蒙毅龙头石
    for(let i = 0; i < list.length; i++) {
      list[i] = `http://192.168.0.68:8000${list[i]}`
    }
  }
  if (This.userid === 'cdz.zxfhgroup.com:8001' || This.userid === 'cdz.zxfhgroup.com:83') { // 龙头石私有服务
    for(let i = 0; i < list.length; i++) {
      const str = removeIpAndPort(list[i]) // 删除ip和端口
      list[i] = `https://cdz.zxfhgroup.com:8000${str}`
    }
  }

  if (This.userid === '172.16.11.11:8001' || This.userid === '172.16.11.11:83') { // 天元重工
    for(let i = 0; i < list.length; i++) {
      const str = removeIpAndPort(list[i]) // 删除ip和端口
      list[i] = `http://172.16.11.11:8000${str}`
    }
  }

  if (This.userid === '10.0.3.165:80' || This.userid === '10.0.3.165:83') { // 浙江永贵
    for(let i = 0; i < list.length; i++) {
      const str = removeIpAndPort(list[i]) // 删除ip和端口
      list[i] = `http://10.0.3.165:8001${str}`
    }
  }
  
  if (This.userid === '192.168.1.33:80' || This.userid === '192.168.1.33:83') { // 万友滤机
    for(let i = 0; i < list.length; i++) {
      const str = removeIpAndPort(list[i]) // 删除ip和端口
      list[i] = `http://192.168.1.33:8001${str}`
    }
  }

  if (This.userid === 'localhost') { // 简晟打包
    for(let i = 0; i < list.length; i++) {
      list[i] = `http://localhost:83${list[i]}`
    }
  }

  if (This.userid === `10.30.201.240:83` || // 中自采集云
  This.userid === '111.9.53.181:83' ||
  This.userid === '10.30.201.240:80' || // 中自业务云
  This.userid === '10.30.201.240:8000') {
    for(let i = 0; i < list.length; i++) {
      list[i] = `http://10.30.201.240:8000${list[i]}`
    }
  }
  return list
}

export function setPCanvas(This) {
  const ipPortRegex = /(\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b):(\d{1,5})\b/ // 提取字符串中的ip的正则表达式
  const useridIp = This.userid.match(ipPortRegex)
  let newIp = null // 当前云组态的ip
  if (useridIp) {
    newIp = useridIp[1]
  } else { // userid使用的是域名
    if (This.userid === 'cdz.zxfhgroup.com' || This.userid === 'cdz.zxfhgroup.com:83') { // 龙头石私有服务
      newIp = 'cdz.zxfhgroup.com'
    }
  }
  let newPort = null // 当前云组态端口
  switch (newIp) {
    case '192.168.0.68': newPort = 8000; break // 蒙毅龙头石 8000 为图片资源端口
    case 'cdz.zxfhgroup.com': newPort = 8000; break // 龙头石内网 8000 为图片资源端口
    case 'localhost': newPort = 83; break // 简晟打包
  }
  if (This.dynalt.config.canvas && This.dynalt.config.canvas[This.canvasCur] && This.dynalt.config.canvas[This.canvasCur].element) {
    This.dynalt.config.canvas[This.canvasCur].element.forEach(item => {
      if (item.value) {
        if (Object.prototype.toString.call(item.value) === '[object Object]') { // value 为对象
          if (!item.value.label || !item.value.label.labelID) {
            item.value.label = {
              labelID: ''
            }
          }
        } else if (typeof item.value === 'string') {
          const match = item.value.match(ipPortRegex)
          if (newIp && newPort) { // 存在新ip和新端口
            if (match && match[1] && match[2]) { // value中包含ip和端口
              const oldIp = match[1] // 以前的ip
              const oldPort = match[2] // 以前的端口
              const oldIpRepeatNum = (item.value.match(eval('/' + oldIp + '/g')) || []).length // oldIp重复的次数
              if (oldIpRepeatNum === 1) {
                if (item.value.indexOf('/private') !== -1 || item.value.indexOf('/image') !== -1) {
                  let notHasIpAndPortStr = item.value
                  if (item.value.indexOf('http://') !== -1) { // 存在 'http://' 前缀
                    if (item.value.indexOf(newIp) === -1) { // 未添加上新ip
                      notHasIpAndPortStr = removeIpAndPort(item.value) // 删除ip和端口后的字符串
                      item.value = `http://${newIp}:${newPort}${notHasIpAndPortStr}`
                    }
                  }
                }
              } else if (oldIpRepeatNum === 2) {
                item.value = item.value.replace(oldIp, newIp) // 替换ip
                item.value = item.value.replace(oldPort, newPort) // 替换端口号
              }
            } else {
              if (item.value.indexOf('/private') !== -1 || item.value.indexOf('/image') !== -1) {
                if (item.value.indexOf(newIp) === -1) { // 未添加上新ip
                  item.value = `http://${newIp}:${newPort}${item.value}`
                }
              }
            }
          }
        }
        if (newIp === 'cdz.zxfhgroup.com') { // 龙头石内网
          const oldTwoIp = '125.67.195.87'
          if (typeof item.value === 'string') { // 去掉rul中的老ip
            const oldTwoIpIndex = item.value.indexOf(oldTwoIp)
            const oldTwoIpLength = oldTwoIp.length
            if (oldTwoIpIndex !== -1) {
              const valueArr = strSetArr(item.value, oldTwoIp)
              const valueEndStr = valueArr[1].slice(oldTwoIpLength - 1)
              item.value = valueArr[0] + newIp + valueEndStr
            }
            if (item.value.indexOf('https') === -1) {
              item.value = item.value.replace('http', 'https')
            }
          } else {
            if (item.value.list) {
              item.value.list.forEach(listItem => {
                if (listItem.img) {
                  const oldTwoIpIndex = listItem.img.indexOf(oldTwoIp)
                  const oldTwoIpLength = oldTwoIp.length
                  if (oldTwoIpIndex !== -1) {
                    const valueArr = strSetArr(listItem.img, oldTwoIp)
                    const valueEndStr = valueArr[1].slice(oldTwoIpLength - 1)
                    listItem.img = valueArr[0] + newIp + valueEndStr
                  }
                  if (listItem.img.indexOf('https') === -1) {
                    listItem.img = listItem.img.replace('http', 'https')
                  }
                }
              })
            }
          }
        }
      }
    })
  }
  return This.dynalt.config.canvas[This.canvasCur]
}

